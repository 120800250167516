import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AdminPage.css';
import { ApproveMembership, ApproveMembershipRenewal, LoadAdminPage, RejectMembership } from '../../Actions';
import i18next from 'i18next';
import { Tab, Tabs } from '../../Components';
import { TableView } from '../../Components/BasicComponents/TableView';
const sortBygivenId = (a, b) => {
    const nameA = a.givenId?.toUpperCase(); // ignore upper and lowercase
    const nameB = b.givenId?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

function mapStateToProps({ app, user }) {
    return {
        app: app,
        user: user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        loadDetails: () => {
            dispatch(LoadAdminPage())
        },
        approve: data => {
            dispatch(ApproveMembership(data));
        },
        reject: data => {
            dispatch(RejectMembership(data));
        },
        approveRenewal: data => {
            dispatch(ApproveMembershipRenewal(data));
        },
    };
};

class _AdminPage extends Component {
    static propTypes = {
        user: PropTypes.object,
        history: PropTypes.object
    };
    componentDidMount() {
        this.props.loadDetails();
    }
    getJointMembershipData(){
        var members = this.props.user.adminData.allMembers;
        var membership = [];
        this.props.user.adminData.pendingMembership.forEach(x=>{
            var mem = members.filter(y=>y.id === x.userId)[0];
            membership.push({
                ...mem,
                ...x
            });
        });
        return membership;
    }
    getThisYearMembers(){
        var members = this.props.user.adminData.allMembers;
        var membership = [];
        this.props.user.adminData.currentMembership.forEach(x=>{
            var mem = members.filter(y=>y.id === x.userId)[0];
            membership.push({
                ...mem,
                ...x
            });
        });
        return membership;
    }
    render() {
        const AllMembersTableColums = [
            {name:"ID",key:"givenId"}, 
            {name:"First Name",key:"firstName"}, 
            {name:"Last Name",key:"lastName"}, 
            {name:"Personal Number",key:"personalNumber"}, 
            {name:"Email",key:"email"}, 
            // {name:"Address",func:"address"}, 
            {name:"Phone Number",key:"phoneNumber"},
            {name:"Membership Status",key:"status"},
            // {name:"Action",func:"action",actions: []},
        ];
        
        const ApprovedMembersTableColums = [
            {name:"ID",key:"givenId"}, 
            {name:"First Name",key:"firstName"}, 
            {name:"Last Name",key:"lastName"}, 
            {name:"Personal Number",key:"personalNumber"}, 
            {name:"Email",key:"email"}, 
            // {name:"Address",func:"address"}, 
            {name:"Phone Number",key:"phoneNumber"},
            // {name:"Action",func:"action",actions: []},
        ];
        
        const PendingMembersTableColums = [
            {name:"ID",key:"givenId"}, 
            {name:"First Name",key:"firstName"}, 
            {name:"Last Name",key:"lastName"}, 
            {name:"Personal Number",key:"personalNumber"}, 
            {name:"Email",key:"email"}, 
            // {name:"Address",func:"address"}, 
            {name:"Phone Number",key:"phoneNumber"},
            {name:"Action",func:"action", actions: [{name: "Approve", onclick: (e)=>this.props.approve({id: e.givenId})}, {name: "Reject", onclick: (e)=>this.props.reject({id: e.givenId})}]}
        ];
        
        const RenewalMembershipTableColums = [
            {name:"ID",key:"givenId"}, 
            {name:"First Name",key:"firstName"}, 
            {name:"Last Name",key:"lastName"}, 
            {name:"Personal Number",key:"personalNumber"},
            {name:"Year",key:"year"},
            {name:"Action",func:"action",actions: [{name: "Approve", onclick: (e)=>this.props.approveRenewal({id: e.id})}]}
        ];
        
        return (
            <div className="register_container">
                <div className='contact_page_header'>
                    <h3>{i18next.t('adminpage.title')}</h3>
                </div>
                <Tabs>
                    <Tab name="All Members">
                        <TableView data={this.props.user.adminData.allMembers.sort(sortBygivenId)} columns={AllMembersTableColums}></TableView>
                    </Tab>
                    <Tab name="Current Members">
                        <TableView data={this.getThisYearMembers().sort(sortBygivenId)} columns={ApprovedMembersTableColums}></TableView>
                    </Tab>
                    <Tab name="Pending for Approval">
                        <TableView data={this.props.user.adminData.pendingMembers.sort(sortBygivenId)} columns={PendingMembersTableColums}></TableView>
                    </Tab>
                    <Tab name="Membership Renewal Requests">
                        <TableView data={this.getJointMembershipData().sort(sortBygivenId)} columns={RenewalMembershipTableColums}></TableView>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export const AdminPage = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(_AdminPage)
);